<template>

  <div v-if="space" style="height:100%" data-testid="SpacePage">

      <SpaceAppBar :spaceUri="spaceUri" dark flat app>

        <template v-slot:append-title>
          <v-hover>
            <template v-slot:default="{ hover }">
        <v-toolbar-title class="font-weight-medium d-flex align-center">
        <div v-if="currentGrid" class="d-flex align-center">

          <EditableText
            :readonly="!canRenameGrid(currentGrid)"
            class="textOverflow pa-1"
            :text="currentGrid.name"
            @submit="val => renameGrid(currentGrid, val)"
            :maxLength="$apptive.constants.nameLengths.grid"
          />

          <GridMoreMenu
            v-show="hover"
            :gridUri="currentGrid.uri"
            :disableDelete="!canDeleteGrid(currentGrid)"
            :disableRename="!canRenameGrid(currentGrid)"
            :disableSetKey="!canSetGridKey(currentGrid)"
            @deleteGrid="deleteGrid(currentGrid.uri, grids.indexOf(currentGrid))"
          />

        </div>
        
        <template v-if="currentView">
          <v-btn
          icon
          x-small
          v-show="!hover"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
          <span class="textOverflow pa-1">
            {{ currentView.name }}
          </span>
        </template>

      </v-toolbar-title>
    </template>
  </v-hover>
        </template>


    </SpaceAppBar>

    <div :style="{ height: '100%' }">
      <router-view />
    </div>

    <div class="text-center">
      <v-progress-circular
        indeterminate
        v-if="loading"
        color="primary"
        width="2"
        size="48"
        class="loader"
      />
    </div>
    <SeatsConsumedAlert :spaceUri="spaceUri" />
    <OnboardingCustomer v-if="user && this.$settings.apptivecom" :user=user></OnboardingCustomer>
</div>
</template>

<script>

import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'
import GridMoreMenu from '@/components/grid/GridMoreMenu.vue'
import errorBus from '@/utils/errorBus.js'
import EditableText from '@/components/EditableText.vue'
import { bootIntercom } from '@/plugins/intercom.js'
import SpaceAppBar from '@/views/SpaceAppBar.vue'
import SeatsConsumedAlert from '@/components/paywall/SeatsConsumedAlert.vue'
import OnboardingCustomer from '../components/onboarding/OnboardingCustomer.vue'

export default {
  name: 'Space',
  props: {
    spaceUri: null,
    gridUri: null,
    sharedSpaceUri: null
  },
  data: () => ({
    loading: false,
  }),
  mounted() {
    this.loading = true
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal,
            customErrorHandling: [403, 404]
          })
          const metaDescription = document.querySelector('meta[name="theme-color"]')
          if( metaDescription != null) {
            metaDescription.setAttribute('content', this.spaceColor)
          }
          document.title = this.space.title()
          // load grid uris
          const gridList = await this.$store.dispatch('loadGridsUris', newVal)
          if (this.$route.name === 'Space' && gridList.length > 0) {
            this.$router.replace({name: 'Grid', params: {gridUri: gridList[0].uri}})
          }
          //boot intercom here when unser is loaded
          bootIntercom( { appId: this.$settings?.intercom.appID })

        } catch(error) {
          const errorOkHandler = async () => {
            if(this.sharedSpaceUri) {
              const sharedSpace = await this.$store.dispatch('AGReadSpaceOperation', {
                spaceUri: this.sharedSpaceUri
              })
              if (sharedSpace && sharedSpace.type === 'sharedSpace' && hasPermission(sharedSpace, [PERMISSIONS.remove])) {
                this.$store.dispatch('AGDeleteSpaceOperation', this.sharedSpaceUri)
              }
            }
            this.$router.replace({name: 'Spaces'})
          }
          if (error.response.status === 404) {
            errorBus.$emit('ERROR', {
              type: 'spaceNotFound',
              okHandler: errorOkHandler
            })
          } else if (error.response.status === 403) {
            errorBus.$emit('ERROR', {
              type: 'spaceAccessDenied',
              okHandler: errorOkHandler
            })
          }
          this.$apptiveErrorReporter.captureException(error)
        } finally {
          this.loading = false
        }
        
      }
    },
    // This allows navigating to the space page without specifying a
    // grid and being redirected to the first grid in this space
    gridUri(newVal) {
      if (!newVal && this.grids && this.grids.length) {
        this.$router.replace({name: 'Grid', params: {gridUri: this.grids[0].uri}})
      }
    }
  },
  provide() {
    const self = this
    return {
      plan: () => self.space?.plan,
      spaceUri: this.spaceUri,
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    grids() {
      return this.$store.getters.gridsOfSpace(this.spaceUri)
    },
    canAddGrid() {
      return hasPermission(this.space, [PERMISSIONS.addGrid])
    },
    user() {
      return this.$store.state.user.user
    },
    spaceColor() {
      return this.space?.color || 'space'
    },
    currentGrid() {
      if (!this.gridUri) return null
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    currentView() {
      // TODO: This is a bit of a hack, we should have a single view type that is
      // used for all stateful views.
      if (!this.$route.params.statefulViewUri && !this.$route.params.formUri) return null
      if (this.$route.params.statefulViewUri) {
        return this.$store.getters.statefulViewWithUri(this.$route.params.statefulViewUri)
      }
      return this.$store.getters.formWithUri(this.$route.params.formUri)
    }
  },
  methods: {
    navigate(gridUri) {
      this.$router.push({name: 'Grid', params: {gridUri: gridUri}})
    },
    addGrid() {
      this.$store.dispatch('AGAddGridOperation', {
        spaceUri: this.space.uri
      }).then((grid) => {
        this.navigate(grid.uri)
      })
    },
    deleteGrid(gridUri, index) {
      this.$store.dispatch('AGDeleteGridOperation', gridUri).then(() => {
        const newIndex = Math.max(index - 1, 0)
        const previousGrid = this.grids[newIndex]
        if (previousGrid) {
          this.navigate(previousGrid.uri)
        } else {
          this.$router.push({name: 'Space', params: {spaceUri: this.spaceUri}})
        }
        this.$store.commit('setNavigationDrawer', this.grids.length > 0)
      })
    },
    fullGridFromEmbedded(embeddedGrid) {
      // TODO : Hacky, we rely on the fact that the full grid with hal links gets loaded by
      // active grid page.
      const gridUri = embeddedGrid._links?.self.href ?? embeddedGrid.uri
      return this.$store.getters.gridWithUri(gridUri)
    },
    canDeleteGrid(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.remove])
    },
    canRenameGrid(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.rename])
    },
    canSetGridKey(embeddedGrid) {
      const grid = this.fullGridFromEmbedded(embeddedGrid)
      return hasPermission(grid, [PERMISSIONS.patch])
    },
    renameGrid(grid, newName) {
      if (grid.name == newName) {
        return
      }
      return this.$store.dispatch('AGRenameGridOperation', {
        gridUri: grid.uri,
        newName: newName
      })
    },
  },
  components: {
    GridMoreMenu,
    EditableText,
    SpaceAppBar,
    SeatsConsumedAlert,
    OnboardingCustomer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
}
.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-toolbar-title >>> .textOverflow {
  max-width: 200px;
}
</style>
