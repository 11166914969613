export function darkColor(color) {
  // use black or whitle label color based on the primary or button color
  // https://www.w3.org/TR/AERT/#color-contrast
  if(!color) return false
  var rgb = convertHex(color)
  var o = Math.round(((parseInt(rgb[0]) * 299) + (parseInt(rgb[1]) * 587) + (parseInt(rgb[2]) * 114)) /1000)
  return (o > 170)
}

function convertHex (color) {
  color = color.replace('#', '')
  const r = parseInt(color.substring(0, 2), 16)
  const g = parseInt(color.substring(2, 4), 16)
  const b = parseInt(color.substring(4, 6), 16)
  return [ r, g, b ]
}
