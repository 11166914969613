<template>
<BaseStatefulView
  :persistentGridUri="gridUri"
  :formUri="formUri"
  :viewTitle="form ? form.name : ''"
  @reloadView="reloadView"
>

  <div v-if="!loading && canUpdateForm" :style="styles" class="d-flex ">

    <!-- desktop as sidebar -->
    <v-sheet
      width="420"
      min-width="320"
      elevation="1"
      class="overflowScroll"
      v-if="!$vuetify.breakpoint.mobile"
    >
      <FormBuilderTabs
        :spaceUri="spaceUri"
        :gridUri="gridUri"
        :formUri="formUri"
        v-on:addField="addField"
        v-on:removeField="removeField"
        v-on:createField="createField"
      />
    </v-sheet>

    <!-- mobile as modal -->
    <v-dialog
      v-model="editMode"
      fullscreen
      v-if="$vuetify.breakpoint.mobile"
      hide-overlay
      transition="dialog-bottom-transition">
      <v-sheet style="min-height: 100vh">
      <v-toolbar
        dark
        color="primary"
        >
        <v-spacer/>
        <v-btn
          text
          dark
          @click="editMode = false"
        >
        {{$t('dialogs.closeButton')}}
        </v-btn>
      </v-toolbar>
      <FormBuilderTabs
        :spaceUri="spaceUri"
        :gridUri="gridUri"
        :formUri="formUri"
        v-on:addField="addField"
        v-on:removeField="removeField"
        v-on:createField="createField"
      />
      </v-sheet>
    </v-dialog>

    <v-container fluid class="overflowScroll pa-0">

      <v-container class="pa-0" align="center" fluid>

          <FormView 
            v-if="form" 
            :form="form"
            :designer="true"
            @reload="reloadView" />

      </v-container>
    </v-container>
  </div>
  <v-card v-if="!loading && !canUpdateForm" class="my-5 mx-auto preview-card">
    <v-container class="" align="center" fluid>
      <FormView :form="form" @reload="clearForm"/>
    </v-container>
  </v-card>
  <FullHeightLoader v-model="loading"/>

  <template v-slot:toolbar-left>
    <FormShareMenu :disabled="!canAddFormLink" :formUri="formUri" :gridUri="gridUri" class="ml-3">
      <v-btn
        :disabled="!canAddFormLink"
        data-testid="shareFormButton"
        slot="dialogButton"
        color="accent"
        text
        small>
        <v-icon small class="mr-1">mdi-share</v-icon>
        {{$t('forms.shareButton')}}
      </v-btn>
    </FormShareMenu>
    <v-btn
      v-if="$vuetify.breakpoint.mobile"
      :disabled="!canAddFormLink"
      @click="editMode = !editMode"
      color="accent"
      text
      small>
      <v-icon small class="mr-1">mdi-pencil</v-icon>
       {{$t('forms.builderFormField.editButton')}}
    </v-btn>
    <FormMoreMenu
      :formUri="formUri"
      :disableDelete="!canDeleteForm"
      v-on:didDeleteForm="didDeleteForm"
    />
  </template>

</BaseStatefulView>
</template>

<script>
import BaseStatefulView from '@/views/BaseStatefulView.vue'
import FormMoreMenu from '@/components/form/FormMoreMenu.vue'
import FormShareMenu from '@/components/form/FormShareMenu.vue'
import FormBuilderTabs from '@/components/form/formbuilder/FormBuilderTabs.vue'
import FullHeightLoader from '@/components/FullHeightLoader.vue'
import FormView from '@/components/form/FormView.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    spaceUri: null,
    gridUri: null,
    formUri: null
  },
  data () {
    return {
      toolbarHeight: 48,
      loading: false,
      showCreateColumn: false,
      editMode: false
    }
  },
  watch: {
    formUri: {
      immediate: true,
      handler() {
        this.loading = true
        Promise.all([
          this.$store.dispatch('AGReadGridOperation', this.gridUri),
          this.$store.dispatch('AGReadFormOperation', this.formUri)
        ]).finally(() => this.loading = false)
      }
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri) ?? {}
    },
    form() {
      return this.$store.getters.formWithUri(this.formUri)
    },
    grid () {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    styles() {
      return {
        'min-height': `calc(100vh - ${this.$vuetify.application.top + this.toolbarHeight}px)`,
        'max-height': `calc(100vh - ${this.$vuetify.application.top + this.toolbarHeight}px)`,
        backgroundColor: this.form.backgroundColor
      }
    },
    canAddFormLink() {
      return hasPermission(this.grid, [PERMISSIONS.addLink])
    },
    canUpdateForm() {
      return hasPermission(this.form, [PERMISSIONS.update])
    },
    canDeleteForm() {
      return hasPermission(this.form, [PERMISSIONS.remove])
    },
    canUpdateFieldKey() {
      return hasPermission(this.grid, [PERMISSIONS.updateFieldKey])
    }
  },
  provide() {
    return {
      canUpdateFieldKey: () => this.canUpdateFieldKey,
      space: this.space
    }
  },
  methods: {
    addField({ aComponent, page }) {
      const field = this.grid.fields.find(field => field.id === aComponent.fieldId)
      this.form.addComponentToPage(aComponent, page, field)
    },
    removeField({ aComponent, page }) {
      this.form.removeComponentFromPage(aComponent, page)
    },
    didDeleteForm() {
      this.$router.back()
    },
    reloadView() {
      this.form.reload()
    },
    createField() {
      this.showCreateColumn = true
    },
    clearForm() {
      this.form.clearForm()
    }
  },
  components: {
    BaseStatefulView,
    FormMoreMenu,
    FormShareMenu,
    FormBuilderTabs,
    FullHeightLoader,
    FormView
  }
}
</script>
<style scoped>

.overflowScroll {
  overflow: scroll;
}

.preview-card {
  max-width: 600px;
}
</style>
