<template>
  <div>
    <v-menu offset-y  v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="black"
          data-testid="showStatefulViewMenuButton"
          v-bind="attrs"
          @click.prevent="on.click"
          icon
          x-small
          >
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <RenameVirtualGridDialog
          :disabled="!canUpdateView"
          :virtualGrid="virtualGrid"
          :statefulView="statefulView"
        />
        <DeleteVirtualGridDialog
          :disabled="!canDeleteView"
          :virtualGrid="virtualGrid"
          :statefulView="statefulView"
        />
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import DeleteVirtualGridDialog from '@/components/gridView/DeleteVirtualGridDialog.vue'
import RenameVirtualGridDialog from '@/components/gridView/RenameVirtualGridDialog.vue'
import {hasPermission, PERMISSIONS} from '@/utils/halUtils.js'

export default {
  props: {
    statefulViewUri: null,
  },
  data() {
    return {
      menu: false,
      virtualGrid: undefined,
      statefulView: undefined
    }
  },
  watch: {
    menu: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
          return
        }
        this.loading = true
        try {
          this.statefulView = await this.$store.dispatch('AGReadStatefulViewOperation', this.statefulViewUri)
          this.virtualGrid = await this.$store.dispatch('AGReadVirtualGridOperation', this.statefulView.parentGridUri)
        } finally {
          this.loading = false
        }
      }
    }
  },
  computed: {
    canAddView() {
      return hasPermission(this.grid, [PERMISSIONS.addView])
    },
    canDeleteView() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.remove])
        && hasPermission(this.statefulView, [PERMISSIONS.remove])
    },
    canUpdateView() {
      return hasPermission(this.virtualGrid, [PERMISSIONS.update])
        && hasPermission(this.statefulView, [PERMISSIONS.patch])
    }
  },
  components: {
    DeleteVirtualGridDialog,
    RenameVirtualGridDialog,
  },
}
</script>
