<template>
  <v-container class="d-flex justify-center align-center full-height" >

    <v-sheet elevation="10" rounded="lg" class="pa-6" width="650" min-height="400">
      <v-row no-gutters>
        <img height="32" :src="logo" />
        <v-spacer></v-spacer>
        <v-btn v-show="error" text color="primary"  @click="logout" class="font-weight-bold" >Logout<v-icon class="ml-1">mdi-logout</v-icon></v-btn>

      </v-row>

      <v-row no-gutters>
      <v-col
        class="d-flex align-center"
        md="6"
        xs="12"
      >
      <div>
        <div class="text-h6">{{ $t("spaceInvitation.inviteText") }}</div>
        <div class="red--text subtitle mt-2">{{ error }}</div>
        <div v-if="errorDescription" class="red--text subtitle-2 mt-2">({{ errorDescription }})</div>
        <v-btn class="mt-5" @click="acceptInvitation" :loading="loading" color="primary">{{ $t("spaceInvitation.inviteAcceptButtonText") }}</v-btn>
      </div>  
      </v-col>
      <v-col
        md="6"
        xs="12"
        class="d-flex justify-center"
      >
      <img height="300" src="/team-spirit-cuate.svg" />

      </v-col>
    </v-row>
  
  </v-sheet>    
  </v-container>


</template>

<script>
import ApiClient from '@/ApiClient'
import { logout } from '@/utils/auth'

export default {

  data() {
    return {
      errorDescription: null,
      error: null,
      loading: false
    }
  },
  computed: {
    logo() {
      return `${this.$settings.app}/logo.svg`
    },
    linkUri() {
      return (new URL(location)).searchParams.get('uri')
    },
  },
  methods: {
    async acceptInvitation() {
      try {
        this.loading = true 
        this.errorDescription = null
        this.error = null
        let response = await ApiClient.getUri(this.linkUri)
        const uri = response.headers['location']
        let space = await this.$store.dispatch('AGReadSpaceOperation', { spaceUri: uri })
        this.$router.push({ name: 'Space', params: {
          spaceUri: space.realSpace,
          sharedSpaceUri: space.spaceUri
        }})
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.error = this.$t('spaceInvitation.errorWrongEmail')
          this.errorDescription = error.response.data.description
        } else {
          this.error = this.$t('spaceInvitation.error')
        }
      } finally {
        this.loading = false 
      }
    },
    logout() {
      logout()
    }
  }
}
</script>

<style>
.full-height {
  height: 100vh;
}</style>