<template>
  <div>
    <div class="d-flex align-center px-2">
      <v-subheader v-show="canAddGrid" class="pl-0 pr-0">{{ $t('spaceMenu.grids') }}</v-subheader>
      <v-spacer />
      <AddGridMenu 
        :spaceUri="space.uri"
        v-if="canAddGrid"
      >
        <template v-slot:activator="{ on: menuOn, attrs }">
          <v-tooltip open-delay="200" top>
            <template v-slot:activator="{ on: tooltipOn }">
              <v-btn
                x-small
                v-bind="attrs"
                v-on="{ ...tooltipOn, ...menuOn }"
                fab
                width="24"
                height="24"
                color="white"
                elevation="2"
                data-testid="openAddGridMenu"
              >
                <v-icon small color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            {{ $t('dialogs.createGrid') }}
          </v-tooltip>
        </template>
      </AddGridMenu>
    </div>

    <!-- List of grids -->
    <div v-if="hasGrids" class="">

    <AGDraggable
      :disabled="!canAddGrid"
      @end="onGridDragEnd"
      @start="onGridDragStart"
      v-model="grids"
      ghost-class="ghost-card"

    >
      <div v-for="(grid, index) in grids" :key="grid.uri">
        <GridNavigationItem
          :gridUri="grid.uri"
          :gridName="grid.name"
          :space="space"
          :index="index"
        />
      </div>
    </AGDraggable>
  </div>

  </div>
</template>

<script>
import GridNavigationItem from './GridNavigationItem.vue'
import AddGridMenu from '@/components/AddGridMenu.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import AGDraggable from '../AGDraggable.vue'

export default {
  name: 'GridsNavigation',
  
  components: {
    GridNavigationItem,
    AddGridMenu,
    AGDraggable
  },

  props: {
    space: {
      type: Object,
      required: true
    },
    grids: {
      type: Array,
      required: true
    }
  },

  watch: {
    space: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('loadGridsUris', newVal.uri)
        } finally {
          this.loading = false
        }
      }
    },
  },

  data() {
    return {
      showAddGridMenu: false
    }
  },

  computed: {
    hasGrids() {
      return this.grids.length > 0
    },
    canAddGrid() {
      return hasPermission(this.space, [PERMISSIONS.addGrid])
    }
  },
  methods: {
    onGridDragStart() {
      // close 
    },
    onGridDragEnd(event) {
      if (event.oldIndex === event.newIndex) return
      const gridUri = this.grids[event.oldIndex].uri
      this.$store.dispatch('AGChangeGridPositionOperation', {
        space: this.space,
        gridUri,
        newPosition: event.newIndex
      })
    }
  }
}
</script>
<style lang="css" scoped>

.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
</style>
