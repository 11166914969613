import store from '@/store/store.js'
import Field from '@/store/models/Field.js'
import HalObject from './HalObject'
import i18n from '@/plugins/i18n'
import FormComponent from '@/store/models/FormComponent.js'
import { parseFilter } from '@/filter/filter.js'
import { AGCondition, AGNoCondition } from '@/filter/conditions.js'
import Vue from 'vue'
import Entity from '@/store/models/Entity.js'
import { v4 as uuidv4 } from 'uuid'

const MIN_CELL_WIDTH = 40

export default class PersistentGrid extends HalObject {
  constructor(data) {
    super(data)
    this.entities = []
    this.fields = data.fields?.map(field => new Field(field)) ?? []
    this.name = data.name
    this.key = data.key
    this.type = data.type
    this.isDefault = true
    this.filter = undefined
    this._cellWidth = 168
  }

  title() {
    return this.name ? this.name : this.id
  }

  loadViewUris() {
    return store().dispatch('loadViewUris', this.uri)
  }

  reload() {
    return store().dispatch('AGReadGridOperation', this.uri)
  }

  addColumn(position, properties) {
    return store().dispatch('AGColumnAddOperation', {
      virtualGridUri: this.uri,
      position: position,
      ...properties
    })
  }

  updatedSince(dateTime) {
    return store().dispatch('AGHasUpdateSince', {
      gridUri: this.uri,
      dateTime: dateTime
    })
  }

  shownFieldIds() {
    return this.fields.map(field => field.id)
  }

  updateShownFields(fieldIds) {
    this._shownFieldIds = fieldIds
  }

  parentGrid() {
    return undefined
  }

  setFilter(filter) {
    this.filter = filter
  }

  newFieldName() {
    const allNames = this.fields.map(field => field.name)
    const newFieldPrefix = i18n.t('virtualGrid.newField')
    const regex = new RegExp('^' + newFieldPrefix + '(\\d+)$')

    const newFieldIndices = allNames
      .map(name => parseInt(regex.exec(name)?.[1]))
      .filter(match => match != null && !isNaN(match))

    newFieldIndices.sort((a, b) => a - b)
    const nextIndex = (newFieldIndices[newFieldIndices.length - 1] ?? 0) + 1

    return newFieldPrefix + nextIndex
  }

  provisionalEntityMatches() {
    return true
  }

  entityMatches() {
    return true
  }

  positionOf(field) {
    const id = typeof field === 'string' ? field : field.id
    return this.fields.findIndex(item => item.id === id)
  }

  emptyEntity() {
    var entity = []
    this.fields.forEach(() => entity.push(null))
    return new Entity({ 'fields': entity, _id: 'draft' })
  }

  _applyColumnRename(oldName, newName) {
    const field = this.fields.find(field => field.name === oldName)
    Vue.set(field, 'name', newName)
  }

  renameColumn(oldName, newName) {
    return store().dispatch('AGRenameColumnOperation',
      {
        grid: this,
        oldName: oldName,
        newName: newName
      }).then(() => {
        this._applyColumnRename(oldName, newName)
      })
  }

  entityChanged(entity, newValue, field) {
    entity.changed(this, newValue, field)
  }

  updateEntity(entity) {
    if (!entity) console.error('entity can not be null')
    if (!entity._id) console.error('entity need to have an valid _id')
    return entity.update(this)
  }

  addEmptyRow() {
    const newEntity = this.emptyEntity()
    return this.addEntity(newEntity)
  }

  addEntity(entity, group) {
    const entityPUTObject = entity.asPUTObjectIn(this)
    return store().dispatch('AGAddEntityOperation', {
      uri: this.uri,
      entity: { fields: Object.values(entityPUTObject) }
    })
      .then(response => {
        let entityUri = response.headers.location
        return store().dispatch('AGFetchEntityOperation', entityUri)
      })
      .then(fetchedEntity => {
        fetchedEntity.provisional = true
        fetchedEntity.group = group
        fetchedEntity.position = this.entities.length + 1
        this.entities.push(fetchedEntity)
        // add entity to pager of the virtual grid if no filter matches
        if(this.entitiesPager && this.entityMatches(fetchedEntity)) {
          this.entitiesPager.addEntity(fetchedEntity)
        }
        return fetchedEntity
      })
  }

  async deleteEntity(entity) {
    const entityIndex = this.entities.findIndex(aEntitiy => aEntitiy._id == entity._id)
    await store().dispatch('AGDeleteEntityOperation', entity)
    this.entities.splice(entityIndex, 1)
    if(this.entitiesPager) {
      this.entitiesPager.remove(entity)
    }
  }

  fieldTypeFor(aFieldId) {
    return this.fields.find(field => field.id === aFieldId).columnType
  }

  iconForField(fieldId) {
    const type = this.fieldTypeFor(fieldId)
    return type.typeIcon
  }

  remainingFieldComponentsInForm(form) {
    if (!form) return []

    // get only ids that are reflected in the form
    const remainingFields = this.fields.filter(field => !form.components.find(comp => field.id === comp.fieldId))
    if (!remainingFields) return []

    // create a component per field
    return remainingFields
      .map(field =>
        new FormComponent(
          field.name,
          field.id,
          field.columnType.defaultFormFieldType
        ))
      .filter(component => !!component.type)
  }

  displayFormat(value, fieldIndex) {
    const formatFunction = this.fields[fieldIndex]?.columnType?.displayFormat ?? (val => val?.toString())
    return formatFunction(value, this.fields[fieldIndex])
  }

  cellWidth() {
    return this._cellWidth
  }

  changeWidth(fieldId, dx) {
    const newSize = this.fieldWidthById(fieldId) + dx
    Vue.set(this.fieldWidths, fieldId, Math.max(newSize, MIN_CELL_WIDTH))
    this.saveFieldWidths()
  }

  fieldWidthById(fieldId) {
    return this.fieldWidths?.[fieldId] ?? this._cellWidth
  }

  gridWidth() {
    var customFieldWidths = this.fields.map(field => this.fieldWidthById(field.id))
    return customFieldWidths.reduce((a, b) => a + b, 0) + this.prependCellWidth
  }

  gridFields() {
    const grid = store().getters.gridContainingVirtualGridWithUri(this.uri)
    if (!grid) return []
    return grid.fields
  }

  shownFieldsOf(entity) {
    return entity.fields
      .map((field, index) => {
        return {
          value: field,
          schemaItem: this.fields[index].type.jsonSchema,
          fieldId: this.fields[index].id
        }
      })
      .filter((field, index) => {
        return this.shownFieldIds().includes(this.fields[index].id)
      })
  }

  filterObject() {
    if (this.filter) {
      if (this.filter instanceof AGCondition) {
        return this.filter
      } else {
        this.filter = parseFilter(this.filter)
        return this.filter
      }
    }
  }

  isFiltered() {
    return this.filterObject() && !(this.filterObject() instanceof AGNoCondition)
  }

  filteredColumnIds() {
    return this.filter?.referencedFieldIds() ?? []
  }

  uriOfEntity(entity) {
    const parentGridUri = this.uri.split('/views')[0]
    return `${parentGridUri}/entities/${entity._id}`
  }

  async setKey(key) {
    const newKey = key || null
    if (this.key === newKey) {
      return
    }
    await store().dispatch('AGSetGridKeyOperation', {
      gridUri: this.uri,
      key: newKey
    })
    this.key = newKey
    return newKey
  }

  sampleEntity() {
    const values = this.fields.map(field => field.columnType.sampleValue?.(field.type))
    return new Entity({
      fields: values,
      _id: `sample_${uuidv4()}`,
    })
  }

  loadLinks() {
    return store().dispatch('AGListGridLinks', this)
  }
}
