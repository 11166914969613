<template>
  <div class="d-flex flex-column">
    <SpaceAppBar :spaceUri="spaceUri" dark flat app>
      <template v-slot:prepend-title>

        <v-btn
        v-if="$route.meta.showBackButton"
        :to="{ name: 'FlowList', params: { spaceUri: spaceUri, scope: 'space' } }"
        icon
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      </template>
    
      <template v-slot:append-title>

      <v-toolbar-title class="font-weight-medium">
        <EditableText
          :readonly="!canRenameFlow || flow == null"
          class="pa-1"
          :text="title"
          @submit="renameFlow"
          data-testid="flowTitle"
          :showPencil="canRenameFlow && flow != null"
        />
      </v-toolbar-title>
    </template>
    </SpaceAppBar>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>
import EditableText from '@/components/EditableText.vue'
import { hasPermission, PERMISSIONS } from '../../utils/halUtils'
import SpaceAppBar from '@/views/SpaceAppBar.vue'

export default {
  props: {
    spaceUri: null,
    flowUri: null
  },
  watch: {
    spaceUri: {
      immediate: true,
      async handler(newVal) {
        this.loading = true
        try {
          await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: newVal
          })
          await this.$store.dispatch('loadGridsUris', newVal)
          if (this.$route.name === 'FlowPage') {
            this.$router.replace({ name: 'FlowList', params: { spaceUri: this.spaceUri, scope: 'space' } })
          }
        } finally {
          this.loading = false
        }
      }
    },
    title: {
      immediate: true,
        async handler(newVal) {
          document.title = newVal
        }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === 'FlowPage') {
      next({ name: 'FlowList', params: { spaceUri: this.spaceUri, scope: 'space' } })
    } else {
      next()
    }
  },
  provide() {
    return {
      plan: () => this.plan,
    }
  },
  computed: {
    flow() {
      if (this.$route.params.flowUri == null) {
        return undefined
      }
      return this.$store.getters.flowWithUri(this.flowUri)
    },
    title() {
      return this.flow?.name ?? 'Flows'
    },
    canRenameFlow() {
      return hasPermission(this.flow, [PERMISSIONS.patch])
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    plan() {
      return this.space?.plan
    },
  },
  methods: {
    renameFlow(newName) {
      this.flow.rename(newName)
    }
  },
  components: {
    EditableText,
    SpaceAppBar
}
}
</script>

<style lang="css" scoped>
.flowPage {
  overflow: hidden;
}
</style>