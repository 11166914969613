import FlowNode from '@/store/models/flow/nodes/FlowNode.js'
import startNodes from '@/constants/startNodes'

export default class StartNode extends FlowNode {
  constructor(data, eventType) {
    super(data, eventType)
    const nodeSpec = startNodes[eventType] ?? startNodes.start

    this.typeName = nodeSpec.typeName
    this.description = nodeSpec.description
    this.icon = nodeSpec.icon
    this.graphIcon = nodeSpec.graphIcon
    this.color = nodeSpec.color
  }

  loadSetupView() {}

  connectableTo() {
    return false
  }
}