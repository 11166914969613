<template>
  <v-sheet class="d-flex flex-column" :style="constants" ref="pickerRoot">
    <div class="d-flex justify-end pa-2">
      <v-btn text color="accent" @click="reset">{{ $t('iconPicker.removeButton') }}</v-btn>
    </div>
    <v-divider></v-divider>
    <div class="d-flex align-center mx-3">
      <v-text-field autofocus clearable solo flat hide-details v-model="filter"
        :placeholder="$t('iconPicker.searchIcons')" />
        <v-sheet width="20" height="20" v-for="aColor in freeColors" @click="didSelectColor(aColor)"  class="mr-2 pointer" rounded v-bind:key="aColor" :color="aColor"></v-sheet>
        <PaywallMenu :feature="$apptive.constants.features.SPACE_COLORS">
          <ColorPicker :swatches="swatches" v-model="color" @change="onColorChange"/>
        </PaywallMenu>
    </div>
    <v-virtual-scroll class="align-self-center" :items="lines" :item-height="itemWidth" height="400" :width="scrollerWidth" bench="2" ref="scroller">
      <template v-slot="{ index, item }">
        <div :key="index" class="d-flex">
          <v-tooltip v-for="icon in item" :key="icon.name" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="() => selectIcon(icon)" v-on="on" v-bind="attrs" class="iconBackground ma-3" :style="iconStyle(icon)">{{ `mdi-${icon.name}` }}</v-icon>
            </template>
            <span>{{ icon.name }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-virtual-scroll>
  </v-sheet>
</template>

<script>
import ColorPicker from './ColorPicker.vue'
import { darkColor } from '@/utils/color'
import { ICON_SIZE } from '@/components/space/SpaceIcon.vue'
import PaywallMenu from '@/components/paywall/PaywallMenu.vue'


const ICON_PADDING_PX = 12

export default {
  props: {
    spaceUri: null,
    active: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      meta: [],
      itemWidth: ICON_SIZE + (ICON_PADDING_PX * 2),
      columns: 12,
      filter: undefined,
      color: '#1664c0',
      selectedIcon: undefined,
      space: undefined,
      realSpace: undefined,
      resizeObserver: undefined,
      swatches: [
        ['#ff453aff', '#30B2C7'],
        ['#F89700', '#32ADE6'],
        ['#ffd60aff', '#007affff'],
        ['#30d158ff', '#5856D6'],
        ['#00C7BE', '#af52deff'],
      ],
      freeColors: [
      '#ff453aff', '#007affff', '#5856D6', '#30d158ff',  '#ffd60aff' 
      ]
    }
  },
  async mounted() {
    const metaModule = await import('@mdi/svg/meta.json')
    this.meta = metaModule.default
    this.resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if(entry.contentBoxSize) {
          const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize
          if (contentBoxSize.inlineSize > 0) {
            this.columns = Math.floor( contentBoxSize.inlineSize / this.itemWidth )
          }
        }
      }
    })
    this.resizeObserver.observe(this.$refs.pickerRoot.$el)
  },
  beforeDestroy() {
    this.resizeObserver.unobserve(this.$refs.pickerRoot.$el)
  },
  watch: {
    active: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) {
          return
        }
        this.loading = true
        try {
          this.space = await this.$store.dispatch('AGReadSpaceOperation', {
            spaceUri: this.spaceUri,
            cached: true
          })
          if (this.realSpaceUri) {
            this.realSpace = await this.$store.dispatch('AGReadSpaceOperation', {
              spaceUri: this.realSpaceUri,
              cached: true
            })
          }
          if (this.space.icon != null) {
            const iconIndex = this.meta.findIndex(icon => icon.name === this.space.icon)
            this.selectedIcon = this.meta[iconIndex]
            // Scroll selected icon into view
            this.$refs.scroller.$el.scrollTop = Math.floor(iconIndex / this.columns) * this.itemWidth
          }
          this.color = this.space.color ?? '#1664c0'
        } finally {
          this.loading = false
        }
      }
    },
    color(newVal) {
      // This allows previewing color changes in real time
      // without making too many requests to the backend
      // the change is pushed to the backend when the color picker is closed (@change)
      this.onColorChange(newVal, false)
    }
  },
  computed: {
    filteredItems() {
      if (!this.filter) return this.meta
      return this.meta.filter(item => 
        item.name.includes(this.filter.toLowerCase()) || 
        item.tags.some(tag => tag.toLowerCase().includes(this.filter.toLowerCase())) || 
        item.aliases.some(alias => alias.toLowerCase().includes(this.filter.toLowerCase()))
      )
    },
    scrollerWidth() {
      return this.itemWidth * this.columns
    },
    lines() {
      return this.filteredItems.reduce((acc, icon, index) => {
        if (index % this.columns === 0) {
          acc.push([])
        }
        acc[ acc.length - 1 ].push(icon)
        return acc
      }, [])
    },
    constants() {
      return {
        '--icon-size': ICON_SIZE + 'px'
      }
    }
  },
  methods: {
    selectIcon(icon) {
      this.selectedIcon = icon
      this.onIconChange(icon)
    },
    iconStyle(icon) {
      if (icon.name !== this.selectedIcon?.name) return
      return {
        'background-color': this.color,
        color: darkColor(this.color) ? 'black' : 'white' 
      }
    },
    reset() {
      this.selectedIcon = this.meta.find(icon => icon.name === 'table')
      this.color = '#1664c0'
      this.onIconChange(this.selectedIcon)
      this.onColorChange(this.color)
    },
    onIconChange(icon) {
      this.$store.dispatch('AGSetSpaceIcon', {spaceUri: this.space.uri, icon: icon.name})
    },
    onColorChange(color, push = true) {
      this.$store.dispatch('AGSetSpaceColor', {spaceUri: this.space.uri, color, push})
    },
    didSelectColor(aColor) {
      this.color = aColor
      this.onColorChange(aColor)
    }
  },
  components: {
    ColorPicker,
    PaywallMenu
  }
}
</script>

<style scoped>
.iconBackground {
  margin-top: 0px;
  width: var(--icon-size);
  height: var(--icon-size);
  border-radius: 20%;
  font-size: calc(var(--icon-size) - 6px);
}

.pointer {
  cursor: pointer;
}
</style>