import Vue from 'vue'
import ApiClient from '@/ApiClient'
import { PERMISSIONS } from '@/utils/halUtils.js'

export default {
  state() {
    return {
      virtualGridLists: {}
    }
  },
  mutations: {
    addVirtualGridList(state, {gridUri, list}) {
      Vue.set(state.virtualGridLists, gridUri, list)
    }
  },
  actions: {
    loadViewUris(context, gridUri) {
      const grid = context.getters.gridWithUri(gridUri)
      const link = grid.getLink(PERMISSIONS.views)
      return ApiClient.getUri(link, undefined, { headers: { Accept: 'application/vnd.apptivegrid.hal' } })
        .then(response => {
          const viewList = response.data.items.map(item => {
            return {
              uri: item._links.self.href,
              name: item.name,
              id: item.id
            }
          })
          context.commit('addVirtualGridList', { gridUri, list: viewList})
          return viewList
        })
    }
  },
  getters: {
    viewsOfGrid: (state) => gridUri => {
      return state.virtualGridLists[gridUri] || []
    },
    parentViewsOfGrid: (state) => gridUri => {
      const views = state.virtualGridLists[gridUri] || []
      return views.filter(view => !view.parentUri)
    },
    childViewsOfParent: (state) => (gridUri, parentUri) => {
      const views = state.virtualGridLists[gridUri] || []
      return views.filter(view => view.parentUri === parentUri)
    }
  }
}