<template>
  <div>
    <!-- Header section with title and add button -->
    <div class="d-flex align-center px-2">
      <v-subheader v-if="canAddPage || hasBlocks" class="pl-0 pr-0">{{ $t('blocks.pages') }}</v-subheader>
      <v-spacer />
      
      <v-tooltip open-delay="200" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="canAddPage"
            :loading="loading"
            x-small
            fab
            width="24"
            height="24"
            color="white"
            elevation="2"
            v-bind="attrs"
            v-on="on"
            @click="addPage" 
            data-testid="addPageButton"
          >
            <v-icon small color="primary">mdi-plus</v-icon>
          </v-btn>
        </template>
        {{ $t('blocks.addPage') }}
      </v-tooltip>
    </div>

    <!-- List of blocks -->
    <div v-if="hasBlocks">
      <div v-for="(block, index) in blocks" :key="block.id || index">
        <BlockNavigationItem
          :blockUri="block.uri" 
          :spaceUri="spaceUri"
          :index="index"
          class="pr-1"
        />
      </div>
    </div>
    <div v-else>
      <v-btn          
        v-if="canAddPage"
        small block align-start color="primary" text 
        @click="addPage">
        <v-icon left>mdi-plus</v-icon>
        {{ $t('blocks.emptyStateBlocksAddPage') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { hasPermission, PERMISSIONS } from '@/utils/halUtils'
import BlockNavigationItem from '@/components/block/editor/BlockNavigationItem.vue'

export default {
  props: {
    spaceUri: {
      type: String,
      default: null
    },
    showNavigationList: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    hasBlocks() {
      return this.blocks.length > 0
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    blocks () {
      return this.$store.getters.blocks
    }
  },
  async created() {
    await this.listBlocks()
    // this.initialNavigation()
  },
  methods: {
    initialNavigation() {
      if (this.blocks.length >= 1) {
        this.$router.replace({ name: 'BlockPage', params: { blockUri: this.blocks[ 0 ].uri } })
      }
      else if (this.$route.name !== 'BlockEditor') {
        // once the last blockpage was deleted, switch to the BlockEditor route
        this.$router.replace({ name: 'BlockEditor'})
      }
    },
    async listBlocks() {
        await this.$store.dispatch('AGListSpaceBlocks', { space: this.space })
    },
    async addPage() {
      this.loading = true
      try {
        const newBlock = await this.$store.dispatch('AGAddPageToBlockOrSpace', this.space)
        await this.listBlocks()
        this.$router.push({ name: 'BlockPage', params: { blockUri: newBlock.uri } }) 
      } finally {
        this.loading = false
      }
    },
  },
  components: {
    BlockNavigationItem
  }
}
</script>

<style
  lang="scss"
  scoped
>
</style>