<template>
  <v-alert
    :value="hasUpdates"
    class="hasUpdatesAlert"
    :style="{ left }"
    elevation="10"
    dismissible
    border="top"
    colored-border
    color="primary"
  >
    <div class="text-subtitle-2 my-1">
      {{$t('views.updateInfo')}}
    </div>
    <v-btn
      data-testid="reloadButton"
      @click="reload"
      color="primary"
      text
      class="pa-0"
    >
      {{$t('views.reloadButton')}}
    </v-btn>
  </v-alert>
</template>

<script>
export default {
  props: {
    gridUri: null,
    interval: {
      default: 5000,
      type: Number
    },
    autoreload: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      hasUpdates: false,
      hasError: false
    }
  },
  mounted() {
    this.startUpdatePolling()
  },
  beforeDestroy() {
    this.stopUpdatePolling()
  },
  computed: {
    grid() {
      return this.$store.getters.gridWithUri(this.gridUri)
    },
    left() {
      return '20px'
    }
  },
  watch: {
    grid (){
      this.stopUpdatePolling()
      this.startUpdatePolling()
    }
  },
  methods: {
    reload() {
      this.$emit('reloadView')
      this.close()
    },
    close() {
      this.hasUpdates = false
      this.hasError = false
    },
    checkForUpdates(dateTime) {
      // save the date to use this instead of a new one after the server responded
      let savedDate = new Date()
      // AC-3351 check if date is more than 4 min in the past (server reqiures less than 5 min)
      if ( (savedDate - dateTime) > (1000 * 60 * 4) ) {
        // here we skip the update for the past time period. 
        dateTime = savedDate
        // reload gridView as it might be outdated
        this.$emit('reloadView')
      }
      this.grid.updatedSince(dateTime)
      .then((result) => {
        if (result.data[0]) {
          //new updates in the view 
          if(this.autoreload) {
            this.$emit('reloadView')
          }
          else {
            this.stopUpdatePolling()
            this.hasUpdates = true
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          this.stopUpdatePolling()
          this.hasError = true
          throw error
        } 
      })
      .finally(() => {
        if (!this.hasUpdates && !this.hasError) {
          this.startUpdatePolling(savedDate)
        }
      })
    },
    startUpdatePolling(dateTime) {
      clearTimeout(this.timeoutId) // Prevent starting polling if already active
      let dateTimeNow = new Date()
      this.timeoutId = setTimeout(() => this.checkForUpdates(dateTime ?? dateTimeNow), this.interval)
    },
    stopUpdatePolling() {
      clearTimeout(this.timeoutId)
    }
  }
}
</script>

<style scoped>
.hasUpdatesAlert {
  z-index: var(--top-of-v-navigation-drawer-zindex);
  position: fixed;
  bottom: 0px;
}
</style>