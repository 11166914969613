import Vue from 'vue'
import ApiClient from '@/ApiClient'
import { PERMISSIONS } from '@/utils/halUtils.js'
import PersistentGrid from '../models/PersistentGrid.js'
export default {
  state() {
    return {
      gridLists: {}
    }
  },
  mutations: {
    addGridList(state, { spaceUri, list }) {
      Vue.set(state.gridLists, spaceUri, list)
    }
  },
  actions: {
    loadGridsUris(context, spaceUri) {
      const space = context.getters.spaceWithUri(spaceUri)
      const link = space.getLink(PERMISSIONS.grids)
      return ApiClient.getUri(
        link,
        {type: 'persistent'},
        { headers: { Accept: 'application/vnd.apptivegrid.hal' } }
      )
        .then(response => {
          const gridList = response.data.items.map(item => {
            const grid = new PersistentGrid(item)
            return grid
          })
          context.commit('addGridList', { spaceUri, list: gridList })
          return gridList
        })
    }
  },
  getters: {
    gridsOfSpace: (state) => spaceUri => {
      return state.gridLists[spaceUri] || []
    },
  }
}
