<template>
  <v-navigation-drawer
    v-if="renderDrawer"
    class="drawer"
    color="#f5f5f5"
    app floating
    v-model="sideNavigation"
    :width="sideNavigationWidth"
  >
  <template v-slot:prepend>
  <div class="d-flex align-center py-3 px-3">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <div 
          class="d-flex ml-1 align-center space-name-container" 
          v-bind="attrs" 
          v-on="on"
        >
          <SpaceIcon class="flex-shrink-0" :space="space" />
          
          <div class="flex-grow-1 min-width-0 mx-2">
            <EditableText
              :readonly="!canPatchSpace"
              class="space-title font-weight-medium"
              :text="space.title()"
              @submit="renameSpace"
              :maxLength="$apptive.constants.nameLengths.space"
              data-testid="spaceTitle"
            />
          </div>

          <SpaceMoreMenu
            v-if="canPatchSpace"
            class="flex-shrink-0"
            :spaceUri="space.uri"
            :spaceName="space.name"
            :disableRename="!canPatchSpace"
            :disableShare="!canShareSpace"
            :disableUploadCsv="!canUploadCsv"
          />
        </div>
      </template>
      {{ space.name }}
    </v-tooltip>
  </div>

    <v-list class="mx-0" dense nav >
      <v-list-item
        link
        color="accent"
        :exact="true"
        :to="{ name: 'Spaces', params: { view: 'all' } }"
        data-testid="homeButton"
      >
        <v-list-item-icon class="mr-2">
          <v-icon color="accent lighten-2">mdi-home-variant-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="accent--text">{{ $t('spaceMenu.home') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </template>
    <v-list dense nav>

      <GridsNavigation 
        :space="space"
        :grids="grids"
      />

    <BlockNavigation
            :spaceUri="space.uri"
            :showNavigationList="true"
            >

    </BlockNavigation>

    </v-list>
    <template v-slot:append>
      
      <v-list dense nav>
        <v-list-item
        v-if="canPatchSpace"
        link
        color="accent"
        :exact="false"
        :to="{ name: 'FlowPage', params: { spaceUri: spaceUri, scope: 'space' } }"
        data-testid="flowButton"
      >

        <v-icon 
          size="22"
          style="transform: rotate(-90deg);">
          mdi-sitemap-outline
        </v-icon>
      <v-list-item-content class="pl-3">
          <v-list-item-title>{{ $t('spaceMenu.flows') }}</v-list-item-title>
        </v-list-item-content>
    </v-list-item>

        <v-list-item
        v-if="canAddShare"
        @click="shareSpaceDialog = true"
        data-testid="shareSpaceButton"
        >
        <v-icon size="22">mdi-account-multiple-plus-outline</v-icon>

        <v-list-item-content class="pl-3">
          <v-list-item-title>{{ $t('dialogs.shareSpaceButton') }}</v-list-item-title>
        </v-list-item-content>
        <ShareSpaceDialog
          :disabled="!canAddShare"
          v-model="shareSpaceDialog"
          :spaceUri="spaceUri"
          :spaceName="space.name"
        />
      </v-list-item>
      
      <HelpMenu
        v-model="helpMenu"
        right offset-x
        v-if="canPatchSpace"

      >
      <template v-slot:activator="{on}">
        <v-list-item
          v-on="on"
          data-testid="helpMenuButton"
          color="accent"
        >
          <v-badge dot color="error" :value="showUnreadBadge">
            <v-icon size="22">mdi-lifebuoy</v-icon>
          </v-badge>
          <v-list-item-content class="pl-3">
            <v-list-item-title>{{ $t('helpMenu.title') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      </HelpMenu>

        <v-list-item
          v-if="canPatchSpace"
          :to="{ name: 'GeneralSpaceSettingsPage' }"
          data-testid="spaceSettingsPageButton"
          color="accent">
          <v-icon size="22">mdi-cog-outline</v-icon>
          <v-list-item-content class="pl-3">
            <v-list-item-title>{{ $t('settings.spaceSettingsTitle') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import ShareSpaceDialog from '@/components/space/ShareSpaceDialog.vue'
import HelpMenu from '../HelpMenu.vue'
import { hasPermission, PERMISSIONS } from '@/utils/halUtils.js'
import SpaceIcon from './SpaceIcon.vue'
import GridsNavigation from '@/components/space/GridsNavigation.vue'
import BlockNavigation from '@/components/block/editor/BlockNavigation.vue'
import EditableText from '@/components/EditableText.vue'
import SpaceMoreMenu from '@/components/space/SpaceMoreMenu.vue'
export const SIDE_NAVIGATION_WIDTH = 260

export default {
  props: {
    spaceUri: null
  },
  beforeMount() {
    this.$store.commit('setSideNavigation', !this.$vuetify.breakpoint.mobile)
    this.$store.dispatch('loadUser')
  },
  provide() {
    const self = this
    return {
      plan: () => self.space?.plan,
      spaceUri: this.spaceUri,
    }
  },
  data() {
    return {
      shareSpaceDialog: false,
      helpMenu: false,
      sideNavigationWidth: SIDE_NAVIGATION_WIDTH
    }
  },
  computed: {
    renderDrawer() {
      return this.space && this.$store.getters.userLoggedIn
    },
    canAddShare() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canAddPage() {
      return hasPermission(this.space, [PERMISSIONS.addBlock])
    },
    sideNavigation: {
      get() {
        return this.$store.state.sideNavigation
      },
      set(newVal) {
        return this.$store.commit('setSideNavigation', newVal)
      }
    },
    hasAvatar() {
      return this.$store.getters.avatarSrc
    },
    space() {
      return this.$store.getters.spaceWithUri(this.spaceUri)
    },
    grids() {
      return this.$store.getters.gridsOfSpace(this.spaceUri)
    },
    userId() {
      return this.$store.getters.userId
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    icon() {
      return this.space?.icon ?? 'mdi-table'
    },
    showUnreadBadge() {
      return this.$intercom?.unreadCount != null && this.$intercom?.unreadCount > 0
    },
    alphaMode() {
      return this.$store.getters.alphaMode
    },
    parentViewsOfGrid() {
      return (gridUri) => this.$store.getters.parentViewsOfGrid(gridUri)
    },
    childViewsOfGrid() {
      return (gridUri, parentUri) => this.$store.getters.childViewsOfParent(gridUri, parentUri)
    },
    canPatchSpace() {
      return hasPermission(this.space, [PERMISSIONS.patch])
    },
    canShareSpace() {
      return hasPermission(this.space, [PERMISSIONS.addShare])
    },
    canUploadCsv() {
      return hasPermission(this.space, [PERMISSIONS.uploadCSV])
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'Spaces', query: { view: 'all' } })
    },
    openHelpMenu() {
      this.HelpMenu = true
    },
    navigateToGrid(gridUri) {
      if (this.$route.params.gridUri !== gridUri) {
        this.$router.push({name: 'Grid', params: { gridUri }})
      }
    },
    renameSpace(newName) {
      if (this.space.name === newName) return
      return this.$store.dispatch('AGRenameSpaceOperation', {
        spaceUri: this.space.uri,
        newName: newName
      })
    }
  },
  components: {
    ShareSpaceDialog,
    HelpMenu,
    SpaceIcon,
    BlockNavigation,
    GridsNavigation,
    EditableText,
    SpaceMoreMenu
}
}
</script>

<style lang="css" scoped>
.drawer {
  max-height: -webkit-fill-available;
}

.space-name-container {
  width: 100%;
}

.min-width-0 {
  min-width: 0;
}

.space-title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
